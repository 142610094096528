import { filters as allFilters } from '../../generated/filters';
import { baseFilterCategory, useFilter } from '../filter/filter-context';
import { filterTypes } from './filters';

const QuickFilterSection = ({ filter, index, ...props }) => (
  <div className='filter-quick flex-col gap-2'>
    {filter.map((subFilter, subIndex) => {
      const Filter = filterTypes[subFilter.type];
      return (
        <Filter
          {...subFilter}
          {...props}
          key={subIndex}
          index={index + '-' + subIndex}
        />
      );
    })}
  </div>
);

const FilterForm = ({ compact = false }) => {
  const { state, closeFilterDialog } = useFilter();

  let filters = allFilters[baseFilterCategory] || [];

  if (compact) {
    filters = filters.slice(0, 2);
  }

  return (
    <>
      {filters.map((filter, index) => {
        // @ts-ignore
        const Filter = filterTypes[filter.type] || QuickFilterSection;
        return (
          <Filter
            {...filter}
            updateImmediately={compact}
            filter={filter}
            key={index}
            index={index}
            current={Object.keys(state.filter)}
            closeFilterDialog={closeFilterDialog}
          />
        );
      })}
    </>
  );
};

export default FilterForm;
