import Slider from 'rc-slider';
import { useEffect, useState } from 'react';
import { SLIDER_STYLES } from './slider';

export const chfTipFormatter = (value) => `CHF ${value}.–`;

const { createSliderWithTooltip } = Slider;
const RangeTooltip = createSliderWithTooltip(Slider.Range);
const Range = Slider.Range;

const RangeInput = ({ onChange, value }) => (
  <div className='relative w-[65px] rounded'>
    <div className='pointer-events-none absolute left-0 flex items-center pl-[6px]'>
      <span className='text-[13px] leading-[27px]'>CHF</span>
    </div>
    <input
      type='text'
      name='price'
      id='price'
      value={value}
      onChange={(e) => onChange(e.target.value ? parseInt(e.target.value) : 0)}
      className='block h-[26px] w-full rounded-[6px] border border-0.6 pl-[35px] pr-[6px] text-right text-[13px] focus:border-green focus:ring-green'
      aria-describedby='price-currency'
    />
  </div>
);

export const RangeSlider = ({
  min,
  max,
  low = 0,
  high = 100,
  onChange = (val) => {},
  tipFormatter = undefined,
  className = '',
  tooltipVisible = true,
  current,
  attribute,
}) => {
  const [rangeValue, setRangeValue] = useState([low, high]);

  useEffect(() => {
    if (current?.indexOf(attribute) === -1) {
      setRangeValue([low, high]);
    }
  }, [current?.indexOf(attribute) > -1]);

  const onSliderChange = (value) => {
    setRangeValue(value);
  };

  const onAfterSliderChange = (value) => {
    onChange(value);
  };

  const EL = tooltipVisible ? RangeTooltip : Range;

  return (
    <div className={`${className}`}>
      {!tooltipVisible && (
        <div className='mb-3 flex justify-between'>
          <RangeInput
            value={rangeValue[0]}
            onChange={(value) => {
              const newValue = [value, rangeValue[1]];
              setRangeValue(newValue);
              onChange(newValue);
            }}
          />
          <RangeInput
            value={rangeValue[1]}
            onChange={(value) => {
              const newValue = [rangeValue[0], value];
              setRangeValue(newValue);
              onChange(newValue);
            }}
          />
        </div>
      )}
      <div className={`ml-[6px] ${tooltipVisible ? '' : 'mx-[6px]'}`}>
        <EL
          defaultValue={rangeValue}
          min={min}
          max={max}
          value={rangeValue}
          onAfterChange={onAfterSliderChange}
          onChange={onSliderChange}
          allowCross={false}
          railStyle={{ backgroundColor: '#000', height: 1, opacity: 0.1 }}
          tipFormatter={tipFormatter}
          tipProps={{
            prefixCls: 'rc-slider-tooltip',
            placement: 'top',
            visible: true,
          }}
          handleStyle={[
            SLIDER_STYLES.handleStyleSmall,
            SLIDER_STYLES.handleStyleSmall,
          ]}
          trackStyle={[
            {
              height: 1,
              backgroundColor: '#688F68',
              borderRadius: 0,
            },
          ]}
        />
      </div>
    </div>
  );
};
