import { Menu, Transition } from '@headlessui/react';
import { Fragment, useEffect, useState } from 'react';
import { t } from '../../generated/i18n';
import { useFilter } from './filter-context';

export const MobileFilterButton = ({ onClick, className = '' }) => (
  <a
    onClick={onClick}
    role='button'
    aria-label={t('filter')}
    className={`${className} flex items-center transition duration-200 hover:text-green`}
  >
    <svg
      width='23'
      height='14'
      viewBox='0 0 23 14'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M12.9375 10.9202C13.5282 10.9202 14 11.3928 14 11.9667C14 12.5406 13.5282 13.0132 12.9375 13.0132H10.0625C9.4718 13.0132 9 12.5406 9 11.9667C9 11.3928 9.4718 10.9202 10.0625 10.9202H12.9375Z'
        fill='black'
        stroke='white'
        strokeWidth='0.75'
      />
      <path
        d='M21.5625 0.375C22.1532 0.375 22.625 0.847552 22.625 1.42146C22.625 1.99537 22.1532 2.46792 21.5625 2.46792H1.4375C0.846801 2.46792 0.375 1.99537 0.375 1.42146C0.375 0.847552 0.8468 0.375 1.4375 0.375H21.5625Z'
        fill='black'
        stroke='white'
        strokeWidth='0.75'
      />
      <path
        d='M17.25 5.64762C17.8407 5.64762 18.3125 6.12017 18.3125 6.69408C18.3125 7.26799 17.8407 7.74055 17.25 7.74055H5.75C5.1593 7.74055 4.6875 7.26799 4.6875 6.69408C4.6875 6.12017 5.1593 5.64762 5.75 5.64762H17.25Z'
        fill='black'
        stroke='white'
        strokeWidth='0.75'
      />
    </svg>

    <span className='ml-2'>{t('filter')}</span>
  </a>
);

export const DesktopFilterButton = ({ onClick, className = '' }) => (
  <a
    onClick={onClick}
    role='button'
    aria-label={t('filter')}
    className={`${className} flex w-full items-center rounded-md border text-sm font-bold uppercase text-green transition duration-200 hover:bg-green hover:text-white lg:border-green lg:py-1`}
  >
    {/* TODO translate */}
    <span className='mx-auto flex content-center items-center justify-center'>
      {t('Mehr Filter')}
      <svg
        className='ml-2'
        width='14'
        height='12'
        viewBox='0 0 14 12'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M0.417969 6.61248H10.0867C10.2661 6.61435 10.4098 6.7606 10.4086 6.9406C10.4073 7.02935 10.3705 7.11435 10.3055 7.17498L6.44297 11.0375L7.32422 11.9187L13.2492 5.98748L7.33047 0.0687256L6.44922 0.949976L10.3117 4.81248C10.443 4.93498 10.4498 5.1406 10.3273 5.27185C10.2667 5.33685 10.1817 5.37435 10.093 5.37498H0.417969V6.61248Z'
          fill='currentColor'
        />
      </svg>
    </span>
  </a>
);

const orders = { selling: 'Relevanz', preis: 'Preis' };

export const FilterSort = ({ className = '' }) => {
  const {
    setSort,
    state: { sort },
  } = useFilter();
  const [orderDesc, setOrderDesc] = useState(true);
  const [orderBy, setOrderBy] = useState('selling');

  useEffect(() => {
    const newSort = `${orderBy}--${orderDesc ? 'desc' : 'asc'}`;
    if (newSort != sort) {
      setSort(`${orderBy}--${orderDesc ? 'desc' : 'asc'}`);
    }
  }, [orderDesc, orderBy]);

  return (
    <div
      className={`${className} relative z-20 flex cursor-pointer items-center gap-2 text-left lg:font-bold lg:uppercase`}
    >
      <Menu>
        <Menu.Button className='flex transform items-center gap-2 text-black duration-300 hover:text-green lg:uppercase'>
          <span>{orders[orderBy]}</span>
          <svg
            width='12'
            height='7'
            viewBox='0 0 12 7'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M10.6016 1.00078L5.80156 5.80078L1.00156 1.00078'
              stroke='black'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
          </svg>
        </Menu.Button>
        <Transition
          as={Fragment}
          enter='transition ease-out duration-300'
          enterFrom='transform opacity-0 scale-95'
          enterTo='transform opacity-100 scale-100'
          leave='transition ease-in duration-75'
          leaveFrom='transform opacity-100 scale-100'
          leaveTo='transform opacity-0 scale-95'
        >
          <Menu.Items
            className={`ring-opacity-5 absolute -top-[10px] right-[39px] flex transform gap-2 rounded-md bg-white p-[10px] text-black duration-300 hover:text-green focus:outline-none ${
              orderBy === 'selling' ? 'flex-col' : 'flex-col-reverse'
            }`}
          >
            <Menu.Item>
              {({ active }) => (
                <button
                  onClick={() => setOrderBy('selling')}
                  className='text-right hover:text-green lg:uppercase'
                >
                  {orders.selling}
                </button>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <button
                  onClick={() => setOrderBy('preis')}
                  className='text-right hover:text-green lg:uppercase'
                >
                  {orders.preis}
                </button>
              )}
            </Menu.Item>
          </Menu.Items>
        </Transition>
      </Menu>
      <button
        onClick={() => setOrderDesc(!orderDesc)}
        className='transform text-black duration-300 hover:text-green'
      >
        <svg
          className={`transform duration-300 ${orderDesc ? 'rotate-180' : ''}`}
          width='21'
          height='16'
          viewBox='0 0 21 16'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            d='M4.74947 15.9991C4.86509 15.9991 5.01866 15.929 5.09321 15.858L9.34309 11.8582C9.53595 11.6715 9.55827 11.3689 9.36274 11.151C9.18729 10.9564 8.84579 10.9515 8.65561 11.1314L5.24946 14.342V0.499986C5.24946 0.223654 5.02536 0 4.74947 0C4.47359 0 4.24949 0.224101 4.24949 0.499986V14.3429L0.843777 11.1323C0.653604 10.9524 0.320131 10.9649 0.136654 11.1519C-0.0570905 11.3488 -0.0396802 11.6751 0.156296 11.859L4.40618 15.8589C4.52135 15.9763 4.63251 15.9978 4.74992 16L4.74947 15.9991Z'
            fill='currentColor'
          />
          <path
            d='M15.7505 0.000893069C15.6349 0.000893079 15.4813 0.0709806 15.4068 0.141961L11.1569 4.14185C10.9641 4.32845 10.9417 4.63112 11.1373 4.84897C11.3127 5.04361 11.6542 5.04852 11.8444 4.86861L15.2505 1.65799L15.2505 15.5C15.2505 15.7763 15.4746 16 15.7505 16C16.0264 16 16.2505 15.7759 16.2505 15.5L16.2505 1.6571L19.6562 4.86772C19.8464 5.04763 20.1799 5.03513 20.3633 4.84808C20.5571 4.65121 20.5397 4.32488 20.3437 4.14096L16.0938 0.141068C15.9786 0.0236609 15.8675 0.0022328 15.7501 7.27451e-07L15.7505 0.000893069Z'
            fill='currentColor'
          />
        </svg>
      </button>
    </div>
  );
};

const ActiveFilter = ({ children, onClick }) => (
  <Transition
    appear={true}
    show={true}
    enter='transition-width duration-300'
    enterFrom='w-[20px]'
    enterTo='w-auto'
    leave='transition-width duration-300'
    leaveFrom='w-auto'
    leaveTo='w-auto'
  >
    <button
      className='flex w-full transform items-center whitespace-nowrap text-left duration-300 hover:text-green'
      onClick={onClick}
    >
      <svg
        width='12'
        height='12'
        viewBox='0 0 12 12'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        className='mr-2 w-[10px]'
      >
        <path d='M1 11L11 1' stroke='#231F20' strokeMiterlimit='10' />
        <path d='M11 11L1 1' stroke='#231F20' strokeMiterlimit='10' />
      </svg>
      {children}
    </button>
  </Transition>
);

export const CurrentFilter = ({
  updateImmediately = false,
  className = '',
}) => {
  const {
    state: { filter, q },
    resetFilters,
    removeFilter,
    removeSearch,
  } = useFilter();

  if (Object.keys(filter).length === 0 && !q) {
    return <div></div>;
  }

  const showDeleteAll =
    Object.keys(filter).length > 1 || (q && Object.keys(filter).length > 0);

  return (
    <div className={`${className} ml-10`}>
      {q && (
        <ActiveFilter onClick={() => removeSearch({ updateImmediately })}>
          Suchergebnisse für&nbsp;<span className='font-bold'>{q}</span>
        </ActiveFilter>
      )}
      {Object.entries(filter)
        .reverse()
        .map((parameter) => (
          <ActiveFilter
            key={parameter[0]}
            onClick={(e) => {
              e.preventDefault();
              removeFilter({ filter: parameter[0], updateImmediately });
            }}
          >
            {parameter[1].label
              .replace('ae', 'ä')
              .replace('ue', 'ü')
              .replace('oe', 'ö')}
          </ActiveFilter>
        ))}

      {showDeleteAll && (
        <button
          className='hidden w-full transform items-center whitespace-nowrap duration-300 hover:text-green lg:flex'
          onClick={(e) => {
            e.preventDefault();
            resetFilters({ updateImmediately });
          }}
        >
          <svg
            width='11'
            height='13'
            viewBox='0 0 11 13'
            fill='none'
            className='mr-1 w-[10px]'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M6.89652 10.4983C6.89652 10.7518 6.69343 10.9555 6.44553 10.9555C6.1976 10.9555 5.99453 10.752 5.99453 10.4983V6.19603C5.99453 5.94259 6.19763 5.73882 6.44553 5.73882C6.69343 5.73882 6.89652 5.94259 6.89652 6.19603V10.4983Z'
              fill='black'
              stroke='white'
              strokeWidth='0.2'
            />
            <path
              d='M4.17582 10.4983C4.17582 10.7518 3.97272 10.9555 3.72482 10.9555C3.47689 10.9555 3.27383 10.752 3.27383 10.4983V6.19603C3.27383 5.94259 3.47693 5.73882 3.72482 5.73882C3.97272 5.73882 4.17582 5.94259 4.17582 6.19603V10.4983Z'
              fill='black'
              stroke='white'
              strokeWidth='0.2'
            />
            <path
              d='M0.975325 5.22485V5.12485H0.875325H0.550996C0.303097 5.12485 0.1 4.92108 0.1 4.66764V3.11997C0.1 2.35489 0.715282 1.73427 1.46942 1.73427H2.71804H2.81804V1.63427V1.4857C2.81804 0.72061 3.43334 0.1 4.18718 0.1H5.9868C6.74064 0.1 7.35593 0.72061 7.35593 1.4857V1.63427V1.73427H7.45593H8.70456C9.4584 1.73427 10.0737 2.35488 10.0737 3.11997V4.66764C10.0737 4.92108 9.87059 5.12485 9.6227 5.12485H9.29837H9.19837V5.22485V11.2047C9.19837 12.1404 8.44567 12.9 7.52309 12.9H2.6506C1.72802 12.9 0.975325 12.1404 0.975325 11.2047V5.22485ZM1.9776 5.12485H1.8776V5.22485V11.2047C1.8776 11.6344 2.22326 11.9856 2.65088 11.9856H7.52337C7.91729 11.9856 8.24166 11.6876 8.29034 11.3047H8.29694V11.2047V5.22485V5.12485H8.19694H1.9776ZM6.35366 1.73427H6.45366V1.63427V1.4857C6.45366 1.22698 6.24545 1.01442 5.98652 1.01442H4.1869C3.96255 1.01442 3.77582 1.17416 3.73034 1.3857H3.71947V1.4857V1.63427V1.73427H3.81947H6.35366ZM9.0717 4.21043H9.1717V4.11043V3.11997C9.1717 2.86125 8.96349 2.64868 8.70456 2.64868H1.46914C1.21058 2.64868 1.00199 2.86086 1.00199 3.11997V4.11043V4.21043H1.10199H9.0717Z'
              fill='black'
              stroke='white'
              strokeWidth='0.2'
            />
          </svg>
          <span className='ml-1'>{t('Alle Filter löschen')}</span>
        </button>
      )}
    </div>
  );
};
